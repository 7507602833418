<template>
    <div class="departments-list">
        <div class="departments-list-filters">
            <el-input v-model="search" style="width: 240px" placeholder="חיפוש" />
        </div>
        <div class="departments-list-content">
            <h2 style="color: red; text-align: center;" v-if="!filter_by_search.length">לא קיימות רשומות!</h2>
            <template v-for="dep in filter_by_search" :key="dep.uid">
                <div class="departments-list-dep">
                    <h2>{{dep.name}}</h2>
                    <el-button @click="$emit('selected_dep', dep)" size="small" round  type="primary">צפייה</el-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { departments } from '../admin/store'
import { computed, ref } from 'vue'
export default {
    emits:['selected_dep'],
    setup () {
        
        const search = ref('')
        const filter_by_search = computed(() => {
            if(!search.value) return departments.value
            return departments.value.filter((dep) => {
                return dep.name.includes(search.value)
            })
        })

        return {
            departments,
            search,
            filter_by_search
        }
    }
}
</script>

<style scoped>
    .departments-list{
        width: 100%;
        height: 100%;
        color: #333;
    }
    .departments-list-filters{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .departments-list-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }
    .departments-list-dep{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-radius: 10px;
        padding: 0 10px;
        margin-bottom: 5px;
    }
    .info-icon{
        color: var(--warning);
        user-select: none;
        cursor: pointer;
    }
</style>